<template>
  <section
    class="invoice-preview-wrapper"
    style="position: relative"
  >
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="roadmap === undefined"
    >
      <h4 class="alert-heading">
        Error fetching order data
      </h4>
      <div class="alert-body">
        No order found with this payment id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'facture-list' }"
        >
          invoice List
        </b-link>
        for other invoice.
      </div>
    </b-alert>
    <b-row
      v-if="roadmap"
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          v-if="roadmap"
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->

          <div
            class="d-flex justify-content-center flex-md-row flex-column invoice-spacing mt-0"
          >

            <div style="color: black">
              <div>
                <img
                  src="@/assets/images/logo/logo.png"
                  width="250px"
                  height="100%"
                >
              </div>

            </div>

          </div>
          <div class="d-flex flex-row justify-content-between">
            <div class="ml-3">
              <h1
                style="color: black"
              >
                <strong> Bon d'argent reçu N° : {{ roadmap.id }} </strong>
              </h1>
            </div>
            <div class="mr-3">
              <h4
                style="color: black"
              >
                <strong> Date : {{ formatFn(roadmap.created_at) }}</strong>

              </h4></div>
          </div>
          <b-card-body class="d-flex flex-row justify-content-between">

            <div>

              <div
                class="ml-2"
                style="border: 2px solid black;"
              >
                <b-card-text
                  class="ml-2 mr-2 mt-2 mb-1 h3"
                  style="color:black"
                >
                  <strong>{{ company.name }}</strong> : Société de livraison à domicile
                </b-card-text>
                <b-card-text
                  class="ml-2 mr-2 mt-2 mb-1 h3"
                  style="color:black"
                >
                  <strong> Matricule fiscale :</strong>  {{ company.tax_identification_number }}
                </b-card-text>
                <b-card-text
                  class="ml-2 mr-2 mt-2 mb-1 h3"
                  style="color:black"
                >
                  <strong>Adresse :</strong> {{ company.address }}
                </b-card-text>
                <b-card-text
                  class="ml-2 mr-2 mt-2 mb-1 h3"
                  style="color:black"
                >
                  <strong>Tel :</strong> {{ company.phone1 }}
                </b-card-text>
              </div>
            </div>
            <div>

              <div
                class="ml-2"
                style="border: 2px solid black"
              >
                <b-card-text
                  class="ml-2 mr-2 mt-2 mb-1 h3"
                  style="color:black"
                >
                  <span class="font-weight-bold">
                    <strong> Nombre des colis : </strong> {{ roadmap.orders.length }}
                  </span>
                </b-card-text>
              </div>
            </div>

          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-card-body class="invoice-padding pt-0">
            <b-table
              class="ta"
              responsive
              :items="orders"
              :fields="columns"
            >

              <template
                #cell(code)="
                  data"
              >
                <qrcode-vue
                  :value="data.item.code.toString()"
                  :size="size"
                  level="H"
                />
                <span>{{ data.item.code }}</span>
                <h4
                  v-if="data.item.is_an_exchange"
                  style="color:red ; font-weight: bold;"
                >
                  Echange
                </h4>
              </template>
              <template #cell(fournisseur)="data">
                <strong style="font-size: 20px">
                  {{ data.item.external_supplier_name !== null ? data.item.external_supplier_name : data.item.fournisseur }}
                </strong>
                <strong
                  v-if="data.item.external_supplier_phone === null"
                  style="font-size: 20px"
                  class="d-block"
                >{{ data.item.telFournisseur }} <br> {{ data.item.telFournisseur2 }}</strong>
                <strong
                  v-else
                  style="font-size: 20px"
                  class="d-block"
                >{{ data.item.external_supplier_phone }}</strong>
              </template>
              <template
                #cell(destinataire)="data"
              >
                <strong style="font-size: 20px">{{ data.item.destinataire }}</strong>
                <strong
                  style="font-size: 20px"
                  class="d-block"
                >{{ data.item.tel }} <br>
                  {{ data.item.tel2 }}
                </strong>
              </template>
              <template
                #cell(gouvernorat)="data"
              >
                <strong style="font-size: 20px">{{ data.item.gouvernorat }}</strong>
                <strong
                  style="font-size: 20px"
                  class="d-block"
                >{{ data.item.delegation }}</strong>
                <strong
                  class="d-block"
                >{{ data.item.adresse }}</strong>
              </template>
              <template
                #cell(prix)="data"
              >
                <strong
                  style="font-size: 20px"
                  class="d-block"
                >                  {{ data.item.prix }}
                </strong>

              </template>
              <template
                #cell(info)="data"
              >
                <strong
                  v-if="data.item.is_discharge == true"
                  class="d-block"
                >Décharge</strong>
                <strong
                  v-if="data.item.is_fragile == true"
                  class="d-block"
                >Fragile</strong>
                <strong
                  v-if="data.item.is_an_exchange == true"
                  class="d-block"
                >Échange</strong>
              </template>
              <template
                #cell(date)="data"
              >
                <strong
                  style="font-size: 20px"
                  class="d-block"
                >                  {{ data.item.date }}
                </strong>

              </template>

              <template #cell(taskDescription)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ data.item.taskTitle }}{{ data.item.taskTitle }}
                </b-card-text>
                <b-card-text class="text-nowrap">
                  {{ data.item.taskDescription }}
                </b-card-text>
              </template>
            </b-table>
            <b-card-body class="d-flex justify-content-between mt-5">
              <!-- Col: Total -->

              <div class="mb-5 ml-1">
                <h3 style="color: black; font-size: 25px">
                  Signature
                </h3>
              </div>
              <div>
                <h1 style="color: black; font-size: 25px">
                  Total : {{ total.toFixed(3) }} DT
                </h1>
              </div>
            </b-card-body>

          </b-card-body>
          <!-- Invoice Description: Total -->

        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Imprimer
          </b-button>

          <!-- Button: Edit -->
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTable,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import storeAuth from '@/store/store'
import QrcodeVue from 'qrcode.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTable,
    BCardText,
    BButton,
    BAlert,
    BLink,
    QrcodeVue,
  },
  data() {
    return {
      p: [],
      roadmap: [],
      orders: [],
      company: {},
      size: 100,
      columns: [
        {
          key: 'code',
          label: 'code',
        },
        {
          key: 'fournisseur',
          label: 'fournisseur',
        },
        {
          key: 'destinataire',
          label: 'destinataire',
        },
        {
          key: 'gouvernorat',
          label: 'Adresse',
        },
        {
          key: 'info',
          label: 'Info',
        },
        {
          key: 'prix',
          label: 'prix',
        },
        {
          key: 'date',
          label: 'date',
        },

        {
          label: 'Action',
          field: 'action',
        },
      ],

    }
  },

  created() {
    this.company = storeAuth.state.company
    this.id = this.$route.params.id
    this.getRoadmap()
  },
  methods: {
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')} | ${h}`
    },
    formatDate(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      return `${day1.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
    },

    async getRoadmap() {
      await axios
        .get(`/api/payments/detail-payment-received/${this.$route.params.id}`)
        .then(response => {
          this.roadmap = response.data

          const { orders } = this.roadmap
          for (let i = 0; i < orders.length; i += 1) {
            if (orders[i].delegation != null) {
              const data = {
                code: orders[i].id,
                gouvernorat: orders[i].governorate,
                delegation: orders[i].delegation,
                fournisseur: orders[i].supplier.username,
                telFournisseur: orders[i].supplier.phone,
                telFournisseur2: orders[i].supplier.phone2,
                tel: orders[i].phone1,
                tel2: orders[i].phone2,
                destinataire: orders[i].name,
                adresse: orders[i].address,
                prix: orders[i].price_ttc,
                date: this.formatDate(orders[i].created_at),
                retour: orders[i].return_number,
                is_discharge: orders[i].is_discharge,
                is_fragile: orders[i].is_fragile,
                is_an_exchange: orders[i].is_an_exchange,
                external_supplier_name: orders[i].external_supplier_name,
                external_supplier_phone: orders[i].external_supplier_phone,
              }
              this.orders.push(data)
            } else {
              const data = {
                code: orders[i].id,
                gouvernorat: orders[i].governorate,
                delegation: null,
                fournisseur: orders[i].supplier.username,
                telFournisseur: orders[i].supplier.phone,
                telFournisseur2: orders[i].supplier.phone2,
                tel: orders[i].phone1,
                tel2: orders[i].phone2,
                destinataire: orders[i].name,
                adresse: orders[i].address,
                prix: orders[i].price_ttc,
                date: this.formatDate(orders[i].created_at),
                retour: orders[i].return_number,
                is_discharge: orders[i].is_discharge,
                is_fragile: orders[i].is_fragile,
                is_an_exchange: orders[i].is_an_exchange,
                external_supplier_name: orders[i].external_supplier_name,
                external_supplier_phone: orders[i].external_supplier_phone,
              }
              this.orders.push(data)
            }
          }
        })
      this.total = this.roadmap.orders.reduce((accumulator, item) => accumulator + Number(item.price_ttc), 0)
    },
  },
  setup() {
    const invoiceData = ref(null)
    const paymentDetails = ref({})
    const sommeFrais = 0
    const total = 0
    const tax = 0
    const sommeHT = 0
    const printInvoice = () => {
      window.print()
    }

    return {
      invoiceData,
      paymentDetails,
      printInvoice,
      sommeHT,
      tax,
      total,
      sommeFrais,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
table,
td,
th {
  text-align: left;
  border: 1px solid black;
}
footer {
  bottom: 0;
  height: auto; /* Footer height */
}
table {
  width: 100%;
  border-collapse: collapse;
}
.mycolor {
  background-color: #01B2FE;
}
.contact {
  background-color: #01B2FE;
  padding: 10px;
  border-radius: 20px;
}
</style>

<style lang="scss">
@media print {
  html, body {
    height: 99%;
  }
  @page {
    size: A4;
  }
 .ta {
    overflow-x: visible;
    font-size: 20px;
    color: black;
  }

  footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto; /* Footer height */
  }

  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  .mycolor {
    background-color: #01B2FE;
    -webkit-print-color-adjust: exact;
  }
  .contact {
    background-color: #01B2FE;
    padding: 20px;
    border-radius: 25px;
    -webkit-print-color-adjust: exact;
  }
  footer footer-light footer-static {
    display: inline-block;
  }
  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  table,
  td,
  th {
    text-align: left;
    border: 1px solid black;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
  * {
        color: black !important;
  }
}
</style>
